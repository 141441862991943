import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../../icons/arrowDown.svg';
import { Text, TextType } from '../dsm/Text';
import Tooltip from '../dsm/Tooltip';
import { ReactComponent as SelectedIcon } from '../../../icons/SelectedDropDownItem.svg';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';

export type DropdownProps = {
  values: Array<DropdownValue>;
  textType?: TextType;
  maxHeightOptions?: number;
  hasSuccessIcon: boolean;
  disabled?: boolean;
  onSelect?: (selectedValueKey: DropdownValue['key']) => void;
};

export type DropdownValue = { key: number; label: string; selected: boolean };

export const DropdownWithInput = (props: DropdownProps) => {
  const { values, textType, maxHeightOptions, hasSuccessIcon, disabled, onSelect } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isClickingDropdown = useRef(false);

  const translate = useTranslate();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const optionRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  useEffect(() => {
    isOpen && containerRef?.current?.focus();
    optionRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [isOpen, optionRefs]);

  const getSelectedLabel = () =>
    values.find((value) => value.selected)?.label || (values.length > 0 && values[0].label);

  const [inputValue, setInputValue] = useState(getSelectedLabel().toString());

  const handleOnOptionClick = (key: DropdownValue['key']) => {
    onSelect && onSelect(key);
    setIsOpen(!isOpen);
  };

  const [focusedIndex, setFocusedIndex] = useState<number | null>(0);
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToFocusedItem = (index: number) => {
    if (!listRef.current) return;
    const listItem = listRef.current.children[index] as HTMLElement;
    if (listItem) {
      listItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) return;

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        setFocusedIndex((prev) => {
          const nextIndex =
            prev === null
              ? 0
              : Math.min(
                  prev + 1,
                  values.filter((it) => it.label.toLowerCase().includes(inputValue.toLowerCase()))
                    .length - 1,
                );
          scrollToFocusedItem(nextIndex);
          return nextIndex;
        });
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        setFocusedIndex((prev) => {
          const nextIndex =
            prev === null
              ? values.filter((it) => it.label.toLowerCase().includes(inputValue.toLowerCase()))
                  .length - 1
              : Math.max(prev - 1, 0);
          scrollToFocusedItem(nextIndex);
          return nextIndex;
        });
        break;
      }
      case 'Enter': {
        event.preventDefault();
        if (focusedIndex !== null) {
          const value = values.filter((it) =>
            it.label.toLowerCase().includes(inputValue.toLowerCase()),
          )[focusedIndex];
          setInputValue(value.label);
          handleOnOptionClick(value.key);
          setIsOpen(false);
        } else {
          setInputValue(getSelectedLabel().toString());
        }
        break;
      }
      case 'Escape': {
        setIsOpen(false);
        break;
      }
    }
  };

  const inputStyles = {
    width: 'calc(100% - 26px)',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#151515',
    border: '1px solid var(--dts_light_grey)',
    boxShadow: 'none',
    outline: 'none',
    borderRadius: '3px',
    padding: '8.5px 16px 8.5px 8px',
    cursor: 'pointer',
  };

  return (
    <>
      <Container
        className={`${disabled && 'disabled'}`}
        ref={containerRef}
        onBlur={() => {
          if (isClickingDropdown.current) {
            isClickingDropdown.current = false;
            return;
          }
          setInputValue(getSelectedLabel().toString());
          setIsOpen(false);
        }}
        onKeyDown={handleKeyDown}
      >
        <input
          ref={inputRef}
          type='text'
          placeholder={translate(LANGUAGE_KEYS.SELECT)}
          value={inputValue}
          style={inputStyles}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onClick={() => {
            !disabled && inputRef.current?.select();
            !disabled && setIsOpen(!isOpen);
          }}
        />
        <DropdownIconStyled className={`${isOpen && 'open'}`} />

        {isOpen &&
          values.filter((it) => it.label.toLowerCase().includes(inputValue.toLowerCase())).length >
            0 && (
            <OptionsContainer
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              onMouseDown={() => {
                isClickingDropdown.current = true;
              }}
            >
              <Options
                ref={listRef}
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
                style={{ maxHeight: `${maxHeightOptions}px` }}
                className='custom-scrollbar '
              >
                {values
                  .filter((it) => it.label.toLowerCase().includes(inputValue.toLowerCase()))
                  .map((value, index) => (
                    <Tooltip
                      key={index}
                      content={value.label}
                      disabled={!visibleTooltips.includes(index)}
                    >
                      <Option
                        mobile_small={mobileSmallSize}
                        mobile={mobileSize}
                        tablet_small={tabletSmallSize}
                        tablet_large={tabletLargeSize}
                        desktop={desktopSize}
                        key={value.key}
                        onClick={() => {
                          setInputValue(value.label);
                          handleOnOptionClick(value.key);
                        }}
                        onMouseEnter={() => setFocusedIndex(index)}
                        className={`${hasSuccessIcon && value.selected && 'active'} ${
                          focusedIndex === index && 'navigate'
                        }`}
                      >
                        <Text
                          ref={(el) => {
                            if (el) optionRefs.current[index] = el;
                          }}
                          type={textType ? textType : TextType.CAPTION_REGULAR}
                          ellipsis={true}
                        >
                          {value.label}
                        </Text>
                        {hasSuccessIcon && value.selected && <CustomSelectedIcon />}
                      </Option>
                    </Tooltip>
                  ))}
              </Options>
            </OptionsContainer>
          )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
`;

export const OptionsContainer = styled.div<DeviceSizeProps>(
  ({ mobile, tablet_small, tablet_large, desktop }) => `
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    margin-top: 15px;
    z-index: 100;
    background-color: var(--dts_white);
    box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    cursor: initial;
    outline: none;
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 24px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 24px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      padding: 24px;
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 16px;
      max-width:136px;
    }
`,
);

export const Options = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 4px;
    flex-shrink: 0;
    //Desktop
    @media (min-width: ${desktop}px) {
      max-height: 176px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      max-height: 176px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      max-height: 176px;
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export const Option = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer !important;
    &.selected,
    &.navigate {
      background-color: var(--dts_withe_background);
    }
    &.active {
      display: flex;
      justify-content: space-between;
      align-item: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
        linear-gradient(0deg, #f2f2f2, #f2f2f2);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 8px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 8px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 6px 8px 6px 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
  position: absolute;
  top: 50%;
  right: 8px;
`;

const CustomSelectedIcon = styled(SelectedIcon)`
  fill: var(--dts_black);
  width: 16px;
  height: 16px;
`;
