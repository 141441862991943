import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { InputText } from '../components/dsm/InputText';
import { InputTextNumber } from '../components/dsm/InputTextNumber';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PrintIcon } from '../../icons/print.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';
import { useInvoiceViewModel } from './InvoiceViewModel';
import Row from '../components/layout/Row';
import { InvoiceUpdate } from '../ui-model/invoice/InvoiceUpdateUiModel';
import { InvoiceUi } from '../ui-model/invoice/InvoiceUiModel';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import InvoicePDF from './InvoicePDF';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { EFacturaStatus, getEfacturaStatusIcon } from '../ui-model/e-factura/E-factura';
import { getFormattedInvoiceDate } from '../../data/DateFormatter';
import { DeviceSizeProps } from '../DeviceInformations';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import E_factura from '../e-factura/E-factura';
import { useAuth } from '../../routeFiles/AuthContext';
import { datePlusDays, mapDay } from '../components/dsm/DateTimePicker';
import DateTimePickerModal from '../components/dsm/DateTimePickerModal';

const TERMEN_FACTURA_DAYS = 15;

function InvoiceDetailBody() {
  const { invoiceId } = useParams();
  const repo = useInvoiceRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const {
    getInvoiceById,
    updateInvoice,
    getEfacturInvoiceStatusById,
    isEnabledEFacturaFeatureAction,
    getEfacturInvoiceStatusByIdFromCache,
  } = useInvoiceViewModel(repo, featureRepo, e_factuarRepository);
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);
  const translate = useTranslate();

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const { userRole } = useAuth();

  const [download, setDownload] = useState<'download' | ''>('');
  const [print, setPrint] = useState<'print' | ''>('');

  const [invoice, setInvoice] = useState<InvoiceUi | null>(null);
  // 0 is cache 1 is Transfer bancar
  const [payType, setPayType] = useState<number | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [invoicePrefix, setInvoicePrefix] = useState<string | null>(null);
  const [invoicePrefixChange, setInvoicePrefixChange] = useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = useState<number | null>(null);
  const [invoiceNumberChange, setInvoiceNumberChange] = useState<boolean>(false);
  const [isChangedAttr, setIsChangedAttr] = useState<boolean>(false);
  const [isOtherChangeItem, setIsOtherChangeItem] = useState<boolean>(false);
  const [errorClass, setErrorClass] = useState<boolean>(false);
  const [invoiceStatusIcon, setInvoiceStatusIcon] = useState<JSX.Element | null>(null);
  const [invoiceStatus, setInvoiceStatus] = useState<string | null>(null);
  const [termenDate, setTermenDate] = useState<Date>(new Date());

  useEffect(() => {
    getInvoiceById(Number(invoiceId)).then((resp) => {
      if (resp !== null && resp.statusCode === 200) {
        if (resp.invoiedata !== null) {
          setPayType(resp.invoiedata.payType);
          setSelectedDate(resp.invoiedata.invoiceDate);
          setTermenDate(datePlusDays(resp.invoiedata.invoiceDate, TERMEN_FACTURA_DAYS));
          setInvoicePrefix(resp.invoiedata.invoicePrefix);
          setInvoiceNumber(resp.invoiedata.invoiceNumber);
          setInvoicePrefixChange(false);
          setInvoiceNumberChange(false);
          setInvoice(resp.invoiedata);
        }
      } else {
        showAlert('Somehing went wrong!', AlertType.FAILD);
      }
    });
  }, [repo.getInvoiceById]);

  useEffect(() => {
    getInvoiceStatus();
  }, [invoice]);

  const getInvoiceStatus = () => {
    if (invoice !== null && invoice !== null && invoice.einvoiceId !== null) {
      const status = getEfacturInvoiceStatusByIdFromCache(invoice.id);
      if (status === null || status === null) {
        getEfacturInvoiceStatusById(invoice.id).then((resp) => {
          setInvoiceStatusIcon(getEfacturaStatusIcon(resp, 24, 28));
          setInvoiceStatus(resp);
        });
      } else {
        setInvoiceStatusIcon(getEfacturaStatusIcon(status.eInvoiceSatus, 24, 28));
        setInvoiceStatus(status.eInvoiceSatus);
      }
    }
  };

  const getInvoiceStatusByAnaf = () => {
    if (invoice !== null && invoice !== null && invoice.einvoiceId !== null) {
      getEfacturInvoiceStatusById(invoice.id).then((resp) => {
        setInvoiceStatusIcon(getEfacturaStatusIcon(resp, 24, 28));
        setInvoiceStatus(resp);
      });
    }
  };

  const renderBillerData = () => {
    return (
      <div className='data-container'>
        <TableTitle>
          <Text type={TextType.BODY_2_BOLD}>
            {translate(LANGUAGE_KEYS.FURNIZOR)}: {invoice?.ownerCompany.name}
          </Text>
        </TableTitle>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.SEDIU)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.address}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.PUNCT_DE_LUCRU)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.workPlaceAddress}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.CIF)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.cui}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.NR_REG_COM)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.nrRegCom}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.BANK)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.bank}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.IBAN)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.codIban}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.CAPITAL_SOCIAL)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.ownerCompany.capitalSocial}</Text>
              </CustomTableTd>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderClientData = () => {
    return (
      <div className='data-container'>
        <TableTitle>
          <Text type={TextType.BODY_2_BOLD}>
            {translate(LANGUAGE_KEYS.CUMPARATOR)}: {invoice?.clientCompany.name}
          </Text>
        </TableTitle>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.SEDIU)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.clientCompany.address}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.PUNCT_DE_LUCRU)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.clientCompany.workPlaceAddress}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.CIF)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.clientCompany.cui}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.NR_REG_COM)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>{invoice?.clientCompany.nrRegCom}</Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.BANK)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>
                  {invoice?.clientCompany.bank === '' ? '-' : invoice?.clientCompany.bank}
                </Text>
              </CustomTableTd>
            </tr>
            <tr>
              <CustomTableTh>
                <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.IBAN)}: </Text>
              </CustomTableTh>
              <CustomTableTd>
                <Text type={TextType.BODY_2}>
                  {invoice?.clientCompany.codIban === '' ? '-' : invoice?.clientCompany.codIban}
                </Text>
              </CustomTableTd>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderWorkDetails = () => {
    return (
      <table>
        <thead>
          <CustomWorkDetailTabelTr>
            <CustomWorkDetailTabelTh className='first-size'>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.NR)}</Text>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CRT)}</Text>
            </CustomWorkDetailTabelTh>
            <CustomWorkDetailTabelTh className='second-size'>
              <Text type={TextType.CAPTION_BOLD}>
                {translate(LANGUAGE_KEYS.DENUMIRE_PRODUSELOR_SERVICIILOR)}
              </Text>
            </CustomWorkDetailTabelTh>
            <CustomWorkDetailTabelTh className='first-size'>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.UM)}</Text>
            </CustomWorkDetailTabelTh>
            <CustomWorkDetailTabelTh className='third-size'>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CANTITATEA)}</Text>
            </CustomWorkDetailTabelTh>
            <CustomWorkDetailTabelTh className='third-size'>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PRET_UNITAR_LEI)}</Text>
            </CustomWorkDetailTabelTh>
            <CustomWorkDetailTabelTh className='third-size'>
              <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.VALOAREA)}</Text>
              <Text type={TextType.CAPTION_BOLD}>-{translate(LANGUAGE_KEYS.LEI)}-</Text>
            </CustomWorkDetailTabelTh>
          </CustomWorkDetailTabelTr>
        </thead>
        <tbody>
          {invoice?.cases.map((c, index) => {
            return (
              <CustomWorkDetailTabelTr key={index}>
                <CustomWorkDetailTabelTd className='first-size'>
                  <Text type={TextType.CAPTION_REGULAR}>{index + 1}</Text>
                </CustomWorkDetailTabelTd>
                <CustomWorkDetailTabelTd className='second-size'>
                  <Text type={TextType.CAPTION_BOLD}>{c.labworksNames}</Text>
                </CustomWorkDetailTabelTd>
                <CustomWorkDetailTabelTd className='first-size'>
                  <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.BUC)}</Text>
                </CustomWorkDetailTabelTd>
                <CustomWorkDetailTabelTd className='third-size'>
                  <Text type={TextType.CAPTION_REGULAR}>1.00</Text>
                </CustomWorkDetailTabelTd>
                <CustomWorkDetailTabelTd className='third-size'>
                  <Text type={TextType.CAPTION_REGULAR}>{c.price}</Text>
                </CustomWorkDetailTabelTd>
                <CustomWorkDetailTabelTd className='third-size'>
                  <Text type={TextType.CAPTION_REGULAR}>{c.price}</Text>
                </CustomWorkDetailTabelTd>
              </CustomWorkDetailTabelTr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderPlataDetails = () => {
    return (
      <PlataDetailConatiner
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <div className='circula-text'>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.FACTURA_CIRCULA)}</Text>
        </div>
        <div className='sub-total'>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.TOTAL)}</Text>
          <Text type={TextType.BODY_BOLD}>{invoice?.totalSum} Ron</Text>
        </div>
      </PlataDetailConatiner>
    );
  };

  const cancelInvoiceUpdate = () => {
    setSelectedDate(invoice?.invoiceDate ? invoice?.invoiceDate : new Date());
    setTermenDate(
      datePlusDays(invoice?.invoiceDate ? invoice?.invoiceDate : new Date(), TERMEN_FACTURA_DAYS),
    );
    setInvoicePrefix(invoice?.invoicePrefix ? invoice?.invoicePrefix : '');
    setInvoiceNumber(invoice?.invoiceNumber ? invoice?.invoiceNumber : -1);
    setPayType(invoice?.payType);
    setIsChangedAttr(false);
    setErrorClass(false);
    setInvoiceNumberChange(false);
    setInvoicePrefixChange(false);
  };

  const saveInvoiceUpdate = () => {
    const params: InvoiceUpdate = { invoiceId: Number(invoiceId) };
    if (payType !== null) {
      params.payType = payType;
    }
    if (selectedDate !== null) {
      params.invoiceDate = selectedDate.getTime();
    }
    if (invoiceNumberChange !== null && invoiceNumber !== null) {
      params.invoiceNumber = invoiceNumber;
    }
    if (invoicePrefixChange && invoicePrefix !== null) {
      params.invoicePrefix = invoicePrefix;
    }
    if (invoicePrefixChange && !invoiceNumberChange && invoice !== null) {
      params.invoiceNumber = invoice.invoiceNumber;
    }
    if (!invoicePrefixChange && invoiceNumberChange && invoice !== null) {
      params.invoicePrefix = invoice.invoicePrefix;
    }
    updateInvoice(params).then((resp) => {
      if (resp.statusCode === 200 && resp.data === false) {
        setErrorClass(true);
        // i18next.t('key', { what: 'i18next', how: 'great' });
        showAlert(
          translate(LANGUAGE_KEYS.ALERT_INVOICE_NR_EXISTS, {
            invoicePrefix: invoicePrefix,
            invoiceNumber: invoiceNumber,
          }),
          AlertType.FAILD,
        );
      } else {
        setInvoice({
          id: Number(invoiceId),
          ownerCompany: invoice!.ownerCompany,
          clientCompany: invoice!.clientCompany,
          invoiceDate: selectedDate!,
          invoiceNumber: invoiceNumber!,
          invoicePrefix: invoicePrefix!,
          payType: payType!,
          cases: invoice!.cases,
          totalSum: invoice!.totalSum,
          einvoiceId: invoice!.einvoiceId,
          einvoiceSentDate: invoice!.einvoiceSentDate,
        });
        setErrorClass(false);
        setInvoiceNumberChange(false);
        setInvoicePrefixChange(false);
        showAlert(
          translate(LANGUAGE_KEYS.ALERT_INVOICE_UPDATE_SUCCESS, {
            invoicePrefix: invoicePrefix,
            invoiceNumber: invoiceNumber,
          }),
          AlertType.SUCCESS,
        );
      }
    });
    setIsChangedAttr(false);
  };

  const InvoiceNumberFirstPartStyle = {
    maxWidth: '102px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '40px',
    color: errorClass ? 'var(--dts_red)' : 'var(--dts_default_blue)',
    border: errorClass ? '1px solid var(--dts_red)' : '1px solid var(--dts_default_blue)',
    boxShadow: 'none',
    outline: 'none',
    padding: '0px 8px 0px 8px',
    textAlign: 'center',
  };

  const InvoiceNumberSecondPartStyle = {
    maxWidth: '127px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '40px',
    color: errorClass ? 'var(--dts_red)' : 'var(--dts_default_blue)',
    border: errorClass ? '1px solid var(--dts_red)' : '1px solid var(--dts_default_blue)',
    boxShadow: 'none',
    outline: 'none',
    padding: '0px 8px 0px 8px',
    textAlign: 'center',
  };

  return (
    <Content
      className={`${
        (invoiceStatus === EFacturaStatus.OK || invoiceStatus !== EFacturaStatus.IN_PRELUCRARE) &&
        'finish'
      }`}
    >
      <ContentHeader
        className={`${isChangedAttr && 'disable'}`}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ActionsContainer>
          <DownloadIcon
            onClick={() => {
              setDownload('download');
              setTimeout(() => setDownload(''), 2000);
            }}
          />
          {invoice !== null && download === 'download' && (
            <InvoicePDF
              invoiceId={invoice.id}
              action={download}
              onClose={() => {}}
              isOpen={false}
            />
          )}
          <PrintIcon
            onClick={() => {
              setPrint('print');
              setTimeout(() => setPrint(''), 2000);
            }}
          />
          {invoice !== null && print === 'print' && (
            <InvoicePDF invoiceId={invoice.id} action={print} onClose={() => {}} isOpen={false} />
          )}
          <EmailComponent href={`mailto:`}>
            <EmailIcon />
          </EmailComponent>
        </ActionsContainer>
        {userRole !== null &&
          invoice !== null &&
          invoice.id !== null &&
          invoice.invoicePrefix !== null &&
          invoice.invoiceNumber !== null &&
          isEnabledEFacturaFeatureAction(userRole) && (
            <E_factura
              isInvoice={true}
              invociceName={`${invoice.invoicePrefix} ${invoice.invoiceNumber}`}
              invoiceId={invoice.id}
              isLoadedFactura={
                invoice.einvoiceId === null
                  ? null
                  : invoiceStatus === EFacturaStatus.OK ||
                    invoiceStatus === EFacturaStatus.IN_PRELUCRARE
              }
              disableButtonAction={isChangedAttr}
              updateStatus={() => {
                getInvoiceStatusByAnaf();
              }}
            />
          )}
      </ContentHeader>
      <ContentDetail>
        <FirstSection
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <RightSection
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <InvoiceNumberContainer
              className={`${
                (invoiceStatus === EFacturaStatus.OK ||
                  invoiceStatus === EFacturaStatus.IN_PRELUCRARE) &&
                'disable'
              }`}
            >
              {invoicePrefix !== null && (
                <InputText
                  width='100%'
                  placeHolder={'AAAA'}
                  initValue={invoicePrefix}
                  onChange={(e) => {
                    if (
                      invoiceStatus !== EFacturaStatus.OK &&
                      invoiceStatus !== EFacturaStatus.IN_PRELUCRARE
                    ) {
                      if (e.target.value !== '' && e.target.value !== invoice?.invoicePrefix) {
                        setInvoicePrefix(e.target.value);
                        setIsChangedAttr(true);
                        setIsOtherChangeItem(true);
                        setInvoicePrefixChange(true);
                      } else if (!isOtherChangeItem) {
                        setIsChangedAttr(false);
                        setInvoicePrefix(e.target.value);
                        setInvoicePrefixChange(false);
                      } else if (e.target.value !== invoice?.invoicePrefix) {
                        setIsChangedAttr(false);
                        setInvoicePrefixChange(false);
                        setInvoicePrefix(e.target.value);
                      }
                    }
                  }}
                  customStyle={InvoiceNumberFirstPartStyle}
                  onKeyDown={() => {}}
                  clickClose={() => {}}
                  isSearch={false}
                  onBlur={() => {}}
                />
              )}
              {invoiceNumber !== null && (
                <InputTextNumber
                  width='100%'
                  placeHolder={'000000'}
                  initValue={invoiceNumber !== null ? invoiceNumber : ''}
                  onChange={(e) => {
                    if (
                      invoiceStatus !== EFacturaStatus.OK &&
                      invoiceStatus !== EFacturaStatus.IN_PRELUCRARE
                    ) {
                      if (e !== '' && Number(e) !== invoice?.invoiceNumber) {
                        setInvoiceNumber(Number(e));
                        setIsChangedAttr(true);
                        setIsOtherChangeItem(true);
                        setInvoiceNumberChange(true);
                      } else if (invoice !== null && !isOtherChangeItem) {
                        setIsChangedAttr(false);
                        setInvoiceNumber(invoice.invoiceNumber);
                        setInvoiceNumberChange(false);
                      } else if (invoice !== null && Number(e) === invoice.invoiceNumber) {
                        setErrorClass(false);
                        setIsChangedAttr(false);
                        setInvoiceNumber(invoice.invoiceNumber);
                        setInvoiceNumberChange(false);
                      }
                    }
                  }}
                  customStyle={InvoiceNumberSecondPartStyle}
                  pattern='[0-9]*'
                />
              )}
            </InvoiceNumberContainer>
            {invoiceStatusIcon !== null && (
              <EfacturaData>
                {invoiceStatusIcon}
                {invoiceStatus === EFacturaStatus.OK && (
                  <EfacturaStatusDescription className='accept'>
                    {invoice !== null && (
                      <Text type={TextType.CAPTION_BOLD}>
                        {translate(LANGUAGE_KEYS.UPLOAD_INDEX, {
                          index_anaf: invoice.einvoiceId,
                        })}
                      </Text>
                    )}
                    {invoice !== null && (
                      <Text type={TextType.CAPTION_REGULAR}>
                        {translate(LANGUAGE_KEYS.UPLOAD_INDEX_DATE, {
                          date: getFormattedInvoiceDate(invoice.einvoiceSentDate),
                        })}
                      </Text>
                    )}
                  </EfacturaStatusDescription>
                )}
                {invoiceStatus === EFacturaStatus.IN_PRELUCRARE && (
                  <EfacturaStatusDescription className='process'>
                    <Text type={TextType.CAPTION_BOLD}>
                      {translate(LANGUAGE_KEYS.INVOICE_PROCESING)}
                    </Text>
                    <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.WAIT)}</Text>
                  </EfacturaStatusDescription>
                )}
                {(invoiceStatus === EFacturaStatus.NOK || invoiceStatus === null) && (
                  <EfacturaStatusDescription className='error'>
                    <Text type={TextType.CAPTION_BOLD}>
                      {translate(LANGUAGE_KEYS.INVOICE_ERROR)}
                    </Text>
                    <Text type={TextType.CAPTION_REGULAR}>
                      {translate(LANGUAGE_KEYS.RESEND_INVOICE)}
                    </Text>
                  </EfacturaStatusDescription>
                )}
              </EfacturaData>
            )}
          </RightSection>
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <DateOrTimeInput
                className={`${
                  (invoiceStatus === EFacturaStatus.OK ||
                    invoiceStatus === EFacturaStatus.IN_PRELUCRARE) &&
                  'disable'
                }`}
                onClick={() =>
                  invoiceStatus !== EFacturaStatus.OK &&
                  invoiceStatus !== EFacturaStatus.IN_PRELUCRARE &&
                  setIsDatePickerOpen(true)
                }
              >
                <Text type={TextType.BODY_2_BOLD}>
                  {translate(LANGUAGE_KEYS.DATE)}: {selectedDate && mapDay(selectedDate.getDate())}.
                  {selectedDate &&
                    mapDay(selectedDate.getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}
                  .{selectedDate && selectedDate.getFullYear()}
                </Text>
              </DateOrTimeInput>
            </div>
            {isDatePickerOpen && (
              <DateTimePickerModal
                date={selectedDate === null ? new Date() : selectedDate}
                isTime={false}
                onSave={(date) => {
                  setSelectedDate(date);
                  setTermenDate(datePlusDays(date, TERMEN_FACTURA_DAYS));
                  setIsDatePickerOpen(false);
                  if (
                    date.getFullYear() !== invoice?.invoiceDate.getFullYear() ||
                    date.getMonth() !== invoice?.invoiceDate.getMonth() ||
                    date.getDay() !== invoice?.invoiceDate.getDay()
                  ) {
                    setIsChangedAttr(true);
                  } else if (!isOtherChangeItem) {
                    setIsChangedAttr(false);
                  }
                }}
                onCancel={() => setIsDatePickerOpen(false)}
              />
            )}
          </>
        </FirstSection>
        <SecondSection
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          {renderBillerData()}
          {renderClientData()}
        </SecondSection>
        <ThirdSection
          style={{ marginBottom: `${isChangedAttr ? '75px' : '0px'}` }}
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          {renderWorkDetails()}
          {!isMobile && (
            <>
              <Text type={TextType.CAPTION_REGULAR}>
                {translate(LANGUAGE_KEYS.TERMEN_DE_PLATA_FACTURA, {
                  duedate: `${mapDay(termenDate.getDate())}-${mapDay(
                    termenDate.getMonth() + 1,
                  )}-${termenDate.getFullYear()}`,
                })}
              </Text>
              {renderPlataDetails()}
            </>
          )}
        </ThirdSection>
        {isMobile && (
          <FourthSection style={{ marginBottom: `${isChangedAttr ? '75px' : '0px'}` }}>
            <Text type={TextType.CAPTION_REGULAR}>
              {translate(LANGUAGE_KEYS.TERMEN_DE_PLATA_FACTURA, {
                duedate: `${mapDay(termenDate.getDate())}-${mapDay(
                  termenDate.getMonth() + 1,
                )}-${termenDate.getFullYear()}`,
              })}
            </Text>
            {renderPlataDetails()}
          </FourthSection>
        )}
      </ContentDetail>
      {isChangedAttr && (
        <BottomContainer>
          <Button
            style={{ width: 'auto' }}
            textType={TextType.CAPTION_BOLD}
            secondary={'secondary'}
            onClick={cancelInvoiceUpdate}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 'auto' }}
            textType={TextType.CAPTION_BOLD}
            onClick={saveInvoiceUpdate}
          >
            Save Invoice
          </Button>
        </BottomContainer>
      )}
    </Content>
  );
}

const Content = styled.div`
  position: relative;
  &.finish {
    .disable,
    .disable input {
      border: unset !important;
    }
  }
`;

const ContentHeader = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    gap: 16px;
    &.disable {
      .efactura-button {
        pointer-events: none;
        opacity: 0.8;
      }
      
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      justify-content: space-between;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    flex-grow: 1;
    opacity: 0.7;
    width: 24px;
    height: 24px;
    fill: var(--dts_black);
    cursor: pointer;
  }
`;

const ContentDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FirstSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 24px;
    background: var(--dts_white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-wrap: wrap;
      gap: 24px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);
const SecondSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 24px;
    border-radius: 3px;
    background: var(--dts_white);
    display: flex;
    align-items: flex-start;
    .data-container {
      flex-basis: 50%;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-wrap: wrap;
      gap: 24px;
      .data-container {
        flex-basis: 100%;
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);
const ThirdSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 24px;
    border-radius: 3px;
    background: var(--dts_white);
    table {
      width: 100%;
      margin-bottom: 8px;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      overflow: auto;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const FourthSection = styled.div`
  padding: 24px;
  border-radius: 3px;
  background: var(--dts_white);
  table {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const RightSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    gap: 8px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-wrap: wrap;
      gap: 24px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const InvoiceNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  &.disable {
    pointer-events: none;
  }
`;

const EfacturaData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const EfacturaStatusDescription = styled.div`
  &.process {
    p {
      color: var(--dts_panding);
    }
  }
  &.error {
    p {
      color: var(--dts_red);
    }
  }
`;

const DateOrTimeInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid var(--dts_default_blue);
  &.disable {
    pointer-events: none;
  }
`;
const CustomTableTh = styled.th`
  text-align: left;
  min-width: 130px;
  max-width: 130px;
`;
const CustomTableTd = styled.td`
  text-align: left;
  width: 100%;
  padding-left: 8px;
  p {
    overflow-wrap: anywhere;
  }
`;

const CustomWorkDetailTabelTr = styled.tr`
  display: flex;
  gap: 1px;
`;

const CustomWorkDetailTabelTh = styled.th`
  padding: 8px;
  background: #18a0fb4d;
  text-align: left;
  display: flex;
  flex-direction: column;
  &.first-size {
    width: 5%;
  }
  &.second-size {
    flex-grow: 1;
  }
  &.third-size {
    width: 8%;
    min-width: 70px;
  }
`;

const CustomWorkDetailTabelTd = styled.td`
  padding: 8px;
  background: var(--dts_withe_background);
  text-align: left;
  &.first-size {
    width: 5%;
  }
  &.second-size {
    flex-grow: 1;
  }
  &.third-size {
    width: 8%;
    min-width: 70px;
  }
`;

const TableTitle = styled.div`
  padding: 2px;
`;

const PlataDetailConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    gap: 1px;
    margin-top: 36px;
    margin-bottom: 8px;
    div {
      background: var(--dts_withe_background);
      padding: 16px 16px;
      p {
        line-height: 1.3;
      }
      &.circula-text {
        flex-grow: 1;
        display: flex;
        p {
          margin: 0;
          align-self: flex-end;
        }
      }
      &.sub-total {
        width: 16%;
        min-width: 142px;
      }
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      div {
      &.sub-total {
        min-width: 90px;
      }
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const BottomContainer = styled(Row)`
  position: fixed;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  padding: 0 24px;
  background-color: var(--dts_white);
  justify-content: end;
  box-shadow: -1px 2px 11px 0 rgba(0, 0, 0, 0.14);
  gap: 16px;
`;
const EmailComponent = styled.a`
  text-decoration: none;
  display: inline-block;
`;

export default InvoiceDetailBody;
