import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Filter } from '../../domain/Filter';
import { SelectionOption } from '../reports/components/FilterBar';

interface FilterContextType {
  reportFilters: Array<{ filter: Filter; value: SelectionOption['value']; editing: boolean }>;
  setReportFilters: (
    filters: Array<{ filter: Filter; value: SelectionOption['value']; editing: boolean }>,
  ) => void;
  dashboardFilters: {
    label: string;
    filterType: number;
    indicator: boolean;
    elementNr: any;
  } | null;
  setDeshboardFilters: (
    filter: { label: string; filterType: number; indicator: boolean; elementNr: any } | null,
  ) => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);
export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [reportFilters, setReportFilters] = useState<
    Array<{ filter: Filter; value: SelectionOption['value']; editing: boolean }>
  >([]);
  const [dashboardFilters, setDeshboardFilters] = useState<{
    label: string;
    filterType: number;
    indicator: boolean;
    elementNr: any;
  } | null>(null);

  return (
    <FilterContext.Provider
      value={{ reportFilters, setReportFilters, dashboardFilters, setDeshboardFilters }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
};
