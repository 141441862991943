import React, { useEffect, useRef, useState } from 'react';
import Row from '../../components/layout/Row';
import { Filter, Filters } from '../../../domain/Filter';
import styled from 'styled-components';
import Container from '../../components/layout/Container';
import { ReactComponent as CloseFilterIcon } from '../../../icons/filters/close.svg';
import usePrevious from '../../hooks/usePrevious';
import { getFormattedDate } from '../ReportsViewModel';
import { CaseStates } from '../../case/CaseState';
import {
  useContactsRepositoryImplementation,
  useCompaniesRepositoryImplementation,
} from '../../../data/user-management/UsersRepositoryImplementation';
import { userManagementViewModel } from '../../user-management/UserManagementViewModel';
import { Text, TextType } from '../../components/dsm//Text';
import useTranslate from '../../translations/useTranslate';
// import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { DRAFT } from '../../case/caseStates';
import { useCountiesRepositoryImplementation } from '../../../data/county/countiesRepositoryImplementation';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';
import { Company } from '../../../domain/user-management/Company';
import DateTimePickerModal from '../../components/dsm/DateTimePickerModal';
import { useFilters } from '../../hooks/FilterContext';

type FilterBarProps = {
  label?: string;
  onChange: (
    filters: Array<{ filter: Filter; value: SelectionOption['value']; editing: boolean }>,
  ) => void;
};

type Option = FilterOption | SelectionOption;

export type FilterOption = {
  kind: 'Filter';
  filter: Filter;
};

export type SelectionOption = {
  kind: 'Selection';
  value:
    | SelectionOptionID
    | SelectionOptionCompany
    | SelectionOptionPatient
    | SelectionOptionCreateDateFrom
    | SelectionOptionCreateDateTo
    | SelectionOptionState;
};

type SelectionOptionID = { kind: 0; value?: number };
type SelectionOptionCompany = {
  kind: 1;
  value?: number;
  displayValue?: string;
  profileColor: string;
};
type SelectionOptionPatient = { kind: 2; value?: string };
type SelectionOptionCreateDateFrom = {
  kind: 3;
  value?: number;
  displayValue?: string;
};
type SelectionOptionCreateDateTo = {
  kind: 4;
  value?: number;
  displayValue?: string;
};
type SelectionOptionState = { kind: 5; value?: number; displayValue?: string };

const FilterBar = (props: FilterBarProps) => {
  const translate = useTranslate();
  const { onChange } = props;

  const contactRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const authRepo = useAuthRepositoryImplementation();
  const countiesRepo = useCountiesRepositoryImplementation();
  const { companies } = userManagementViewModel(contactRepo, companiesRepo, authRepo, countiesRepo);

  const { reportFilters, setReportFilters } = useFilters();

  // Filters and search term
  const [filtersValue, setFiltersValue] =
    useState<Array<{ filter: Filter; value: SelectionOption['value']; editing: boolean }>>(
      reportFilters,
    );
  const previousFilters = usePrevious(filtersValue);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [openValueDropdown, setOpenValueDropdown] = useState<boolean>(false);

  const refInput = useRef<HTMLInputElement>(null);
  const refDropDown = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLElement>>([]);

  const defaultDatePickerOptions = { isOpen: false, onCancel: () => {}, onSave: () => {} };

  const [datePickerOptions, setDatePickerOptions] = useState<{
    isOpen: boolean;
    onCancel: () => void;
    onSave: (date: Date) => void;
  }>(defaultDatePickerOptions);

  const handleFilterClick = (filter: Filter) => {
    setFiltersValue((prevState) => [
      ...prevState,
      {
        filter: filter,
        value: { kind: filter.id, profileColor: '' },
        editing: true,
      },
    ]);
  };

  const handleSelection = (filter: Filter, value: SelectionOption['value']) => {
    setFiltersValue((prevState) =>
      prevState.map((f) => {
        if (f.filter.id === filter.id) {
          return {
            ...f,
            value: value,
            editing: false,
          };
        }
        return f;
      }),
    );
  };

  useEffect(() => {
    if (previousFilters?.length === filtersValue.length) {
      for (let i = 0; i < previousFilters.length; ++i) {
        if (previousFilters[i].editing && !filtersValue[i].editing) {
          onChange(filtersValue);
          setReportFilters(filtersValue);
        }
      }
    } else {
      if ((previousFilters?.length || 0) > filtersValue.length) {
        onChange(filtersValue.filter((f) => f.value.value));
        setReportFilters(filtersValue.filter((f) => f.value.value));
      }
    }
  }, [filtersValue]);

  function setRef(e) {
    if (e?.target !== refInput?.current) {
      if (openFilterDropdown) setOpenFilterDropdown(false);
      if (!openValueDropdown) setOpenValueDropdown(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', setRef);
    return () => window.removeEventListener('resize', setRef);
  }, [openFilterDropdown, openValueDropdown]);

  const getFilterValue = (filterValue) => {
    if (
      filterValue.kind === 3 ||
      filterValue.kind === 4 ||
      filterValue.kind === 5 ||
      filterValue.kind === 1
    ) {
      return filterValue.displayValue;
    } else {
      return filterValue.value;
    }
  };

  const getDropDownLeftParam = () => {
    const offset = refInput.current?.offsetLeft;
    if (offset === undefined) {
      return 2;
    }
    return offset + 2;
  };

  const handleOnOptionClick = (filter: Filter, value: Option) => {
    value.kind === 'Filter' && handleFilterClick(value.filter);
    value.kind === 'Selection' && handleSelection(filter, value.value);
  };

  const dropdownOnKeyEvents = (e) => {
    let selectedIndex = -1;
    itemsRef.current.forEach((itemRef, index) => {
      if (itemRef.classList.contains('selected')) selectedIndex = index;
    });
    if (e.key === 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();
      if (selectedIndex === -1) {
        itemsRef.current[selectedIndex + 1].classList.add('selected');
        return;
      }
      if (selectedIndex + 1 !== itemsRef.current.length) {
        itemsRef.current[selectedIndex].classList.remove('selected');
        itemsRef.current[selectedIndex + 1].classList.add('selected');
      }
    }
    if (e.key === 'ArrowUp') {
      e.stopPropagation();
      e.preventDefault();
      if (selectedIndex === -1) {
        return;
      }
      if (selectedIndex - 1 !== -1) {
        itemsRef.current[selectedIndex].classList.remove('selected');
        itemsRef.current[selectedIndex - 1].classList.add('selected');
      }
    }
    if (e.key === 'Enter' && itemsRef.current[selectedIndex] !== undefined) {
      e.stopPropagation();
      e.preventDefault();
      itemsRef.current[selectedIndex].click();
    }
    return;
  };

  const filterList = () => {
    refInput.current?.focus();
    itemsRef.current = [];
    const currentFilters = Filters.filter((f) => {
      if (filtersValue.length > 0) {
        const filter = filtersValue.find((selectFilter) => selectFilter.filter.id === f.id);
        return filter === undefined;
      } else {
        return true;
      }
    }).filter((f) => f.name.toLowerCase().includes((searchTerm || '').toLowerCase()));
    return (
      currentFilters.length > 0 && (
        <FilteredSearchInputDropdownMenu
          ref={refDropDown}
          style={{ left: getDropDownLeftParam() + 'px' }}
          className={`dropdown-menu  ${openFilterDropdown && 'open'}`}
        >
          <FilterDropDown className='filter-dropdown'>
            {currentFilters.map((filter, index) => {
              return (
                <FilterDropdownItem
                  className='filter-dropdown-item'
                  ref={(el) => {
                    if (el) itemsRef.current[index] = el;
                  }}
                  key={filter.id + '-filter'}
                  onClick={() => {
                    handleOnOptionClick(filter, { kind: 'Filter', filter: filter });
                    setOpenFilterDropdown(false);
                    setTimeout(() => {
                      setOpenValueDropdown(true);
                    }, 100);
                    itemsRef.current = [];
                    setSearchTerm('');
                  }}
                >
                  <FilterDropdownItemContent>
                    {filter.icon}
                    {translate(filter.name)}
                  </FilterDropdownItemContent>
                </FilterDropdownItem>
              );
            })}
          </FilterDropDown>
        </FilteredSearchInputDropdownMenu>
      )
    );
  };

  const valueDropdownList = (filter: Filter | undefined) => {
    itemsRef.current = [];
    if (filter === undefined) return;
    switch (filter.id) {
      case Filters[1].id: {
        const filterCompanies = companies === undefined ? [] : companies;
        let currentFilters: Array<Company> = [];
        if (filterCompanies !== null) {
          currentFilters = filterCompanies.filter((c) =>
            c.name.toLowerCase().includes((searchTerm || '').toLowerCase()),
          );
        }
        return (
          currentFilters.length > 0 && (
            <FilteredSearchInputDropdownMenu
              style={{ left: getDropDownLeftParam() + 'px' }}
              className={`dropdown-menu  ${openValueDropdown && 'open'}`}
            >
              <FilterDropDown className='filter-dropdown'>
                {currentFilters.map((comp, index) => {
                  return (
                    <FilterDropdownItem
                      ref={(el) => {
                        if (el) itemsRef.current[index] = el;
                      }}
                      className='filter-dropdown-item'
                      key={comp.id}
                      onClick={() => {
                        handleOnOptionClick(filter, {
                          kind: 'Selection',
                          value: {
                            kind: 1,
                            value: comp.id,
                            displayValue: comp.name,
                            profileColor: '',
                          },
                        });
                        setOpenValueDropdown(false);
                        itemsRef.current = [];
                        setSearchTerm('');
                      }}
                    >
                      <FilterDropdownItemContent>
                        <Text type={TextType.CAPTION_REGULAR} ellipsis={true}>
                          {comp.name}
                        </Text>
                      </FilterDropdownItemContent>
                    </FilterDropdownItem>
                  );
                })}
              </FilterDropDown>
            </FilteredSearchInputDropdownMenu>
          )
        );
      }
      case Filters[3].id: {
        setOpenValueDropdown(false);
        setDatePickerOptions({
          isOpen: true,
          onCancel: () => {
            setDatePickerOptions(defaultDatePickerOptions);
          },
          onSave: (date) => {
            handleOnOptionClick(filter, {
              kind: 'Selection',
              value: {
                kind: 3,
                value: date.getTime(),
                displayValue: getFormattedDate(date.getTime()),
              },
            });
            setDatePickerOptions(defaultDatePickerOptions);
          },
        });
        break;
      }
      case Filters[4].id: {
        setOpenValueDropdown(false);
        setDatePickerOptions({
          isOpen: true,
          onCancel: () => {
            setDatePickerOptions(defaultDatePickerOptions);
          },
          onSave: (date) => {
            handleOnOptionClick(filter, {
              kind: 'Selection',
              value: {
                kind: 4,
                value: date.getTime(),
                displayValue: getFormattedDate(date.getTime()),
              },
            });
            setDatePickerOptions(defaultDatePickerOptions);
          },
        });
        break;
      }
      case Filters[5].id: {
        const currentFilters = CaseStates.filter((caseState) => caseState.id !== DRAFT).filter(
          (c) => c.name.toLowerCase().includes((searchTerm || '').toLowerCase()),
        );
        return (
          currentFilters.length > 0 && (
            <FilteredSearchInputDropdownMenu
              style={{ left: getDropDownLeftParam() + 'px' }}
              className={`dropdown-menu  ${openValueDropdown && 'open'}`}
            >
              <FilterDropDown className='filter-dropdown'>
                {currentFilters.map((state, index) => {
                  return (
                    <FilterDropdownItem
                      className='filter-dropdown-item'
                      ref={(el) => {
                        if (el) itemsRef.current[index] = el;
                      }}
                      key={state.id}
                      onClick={() => {
                        handleOnOptionClick(filter, {
                          kind: 'Selection',
                          value: { kind: 5, value: state.id, displayValue: state.name },
                        });
                        setOpenValueDropdown(false);
                        setSearchTerm('');
                      }}
                    >
                      <FilterDropdownItemContent>
                        <Text type={TextType.CAPTION_REGULAR} ellipsis={true}>
                          {translate(state.name)}
                        </Text>
                      </FilterDropdownItemContent>
                    </FilterDropdownItem>
                  );
                })}
              </FilterDropDown>
            </FilteredSearchInputDropdownMenu>
          )
        );
      }
      default:
        setOpenValueDropdown(false);
        break;
    }
  };

  const deleteFilterValue = (filter: {
    filter: Filter;
    value: SelectionOption['value'];
    editing: boolean;
  }) => {
    setFiltersValue((prevState) => [
      ...prevState.map((f) => {
        if (filter.filter.id === f.filter.id) {
          if (filter.value.kind === 0 || filter.value.kind === 2) {
            return {
              ...f,
              value: { kind: filter.value.kind, profileColor: '' },
              editing: true,
            };
          }
          if (
            filter.value.kind === 3 ||
            filter.value.kind === 4 ||
            filter.value.kind === 5 ||
            filter.value.kind === 1
          ) {
            return {
              ...f,
              value: { kind: filter.value.kind, profileColor: '' },
            };
          }
        }
        return f;
      }),
    ]);
  };

  const checkOpeningFilterDropdown = (): boolean => {
    if (filtersValue.length === 0) return true;
    return getFilterValue(filtersValue[filtersValue.length - 1]?.value) !== undefined;
  };

  return (
    <ContainerStyled orientation='Horizontal'>
      <ScrollConatiner className='scroll-container'>
        <TokensContainer className='tokens-container list-unstyled'>
          {filtersValue.map((filter) => {
            return (
              <FilteredSearchToken key={filter.filter.id}>
                <FilterLabelConatiner>
                  <FilterLabel className='filter-label'>
                    {translate(filter.filter.name)}
                  </FilterLabel>
                  <FilterLabel
                    className={`${
                      getFilterValue(filter.value) === undefined && 'need-margin'
                    } filter-label`}
                  >
                    =
                  </FilterLabel>
                  {getFilterValue(filter.value) !== undefined && (
                    <FilterValueContainer className='filter-label'>
                      <FilterLabel className='filter-label'>
                        {translate(getFilterValue(filter.value))}
                      </FilterLabel>
                      <CloseFilterIcon
                        width={16}
                        height={16}
                        onClick={() => {
                          setFiltersValue((prevState) => [
                            ...prevState.filter((f) => {
                              if (filter.filter.id !== f.filter.id) return f;
                            }),
                          ]);
                          setSearchTerm('');
                        }}
                      />
                    </FilterValueContainer>
                  )}
                </FilterLabelConatiner>
              </FilteredSearchToken>
            );
          })}
          <InputToken className='input-token'>
            <FormControl
              ref={refInput}
              className='form-control filtered-search'
              placeholder={`${filtersValue.length > 0 ? '' : 'Search or filter results…'}`}
              onClick={() => {
                checkOpeningFilterDropdown() && setOpenFilterDropdown(true);
                !checkOpeningFilterDropdown() && setOpenValueDropdown(true);
              }}
              onKeyDown={(e) => {
                const filter = filtersValue[filtersValue.length - 1];
                if (openFilterDropdown || openValueDropdown) {
                  dropdownOnKeyEvents(e);
                }
                if (
                  e.key === 'Enter' &&
                  filter !== undefined &&
                  filter.value.value === undefined &&
                  (filter.value.kind === 0 || filter.value.kind === 2)
                ) {
                  e.stopPropagation();
                  e.preventDefault();
                  setFiltersValue((prevState) => [
                    ...prevState.map((f) => {
                      if (filter.filter.id === f.filter.id) {
                        if (f.value.kind === 0 && searchTerm !== undefined)
                          return {
                            ...f,
                            value: {
                              ...f.value,
                              value: parseInt(searchTerm) || undefined,
                            },
                            editing: false,
                          };
                        if (f.value.kind === 2 && searchTerm !== undefined)
                          return {
                            ...f,
                            value: { ...f.value, value: searchTerm || undefined },
                            editing: false,
                          };
                        if (filter.value.kind === 1 || filter.value.kind === 5)
                          return { ...filter, editing: false };
                        return {
                          ...filter,
                          editing: false,
                        };
                      } else return f;
                    }),
                  ]);
                  setSearchTerm('');
                }
                if (e.key === 'Backspace' && searchTerm === '') {
                  e.stopPropagation();
                  e.preventDefault();
                  if (filter !== undefined && filter.value.value !== undefined) {
                    deleteFilterValue(filter);
                    setOpenFilterDropdown(false);
                    setTimeout(() => {
                      setOpenValueDropdown(true);
                    }, 100);
                  } else {
                    setFiltersValue((prevState) => [
                      ...prevState.filter((f) => {
                        if (filter.filter.id !== f.filter.id) return f;
                      }),
                    ]);
                    setOpenValueDropdown(false);
                    setTimeout(() => {
                      setOpenFilterDropdown(true);
                    }, 100);
                  }
                }
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            />
          </InputToken>
        </TokensContainer>
      </ScrollConatiner>

      {openFilterDropdown && filterList()}
      {openValueDropdown && valueDropdownList(filtersValue[filtersValue.length - 1]?.filter)}

      {filtersValue.length > 0 && (
        <CloseFilterIcon
          className='all-filter_delete'
          width={16}
          height={16}
          onClick={() => {
            setFiltersValue([]);
            setSearchTerm('');
          }}
        />
      )}

      {datePickerOptions.isOpen && (
        <DateTimePickerModal
          isTime={false}
          onCancel={datePickerOptions.onCancel}
          onSave={datePickerOptions.onSave}
        />
      )}
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  background-color: var(--dts_light_grey);
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 7px 0.25rem;
  cursor: pointer;
  flex-shrink: 0;
  background-color: var(--dts_white);
  border: 1px solid var(--dts_light_grey);

  .all-filter_delete {
    padding: 0.25rem;
    margin-right: 2px;
    &:hover {
      path {
        fill: var(--dts_dark_grey);
      }
    }
  }
`;

const FilterLabel = styled(Row)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--dts_black);
  background-color: var(--dts_withe_background);
  padding: 2px 7px;
  border-radius: 2px;
  white-space: nowrap;
  text-transform: capitalize;
  height: 18px;

  &.need-margin {
    margin-right: 2px;
  }
`;

const ScrollConatiner = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
`;

const TokensContainer = styled.ul`
  display: flex;
  flex: 1;
  padding-left: 12px;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  width: 1px;
  &.list-unstyled {
    list-style: none;
  }
`;

const FilteredSearchToken = styled.li`
  display: flex;
  flex-shrink: 0;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const InputToken = styled.li`
  max-width: 200px;
  padding: 0;

  &:only-child,
  &:last-child {
    flex: 1;
    max-width: inherit;
  }
`;

const FormControl = styled.input`
  display: block;
  width: 100%;
  font-weight: 400;
  color: #333238;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  position: relative;
  min-width: 200px;
  line-height: inherit;
  outline: none;
  box-shadow: none;
  padding: 6px 0px 6px 0px;
`;

const FilteredSearchInputDropdownMenu = styled.div`
  position: absolute;
  width: auto;
  top: 100%;
  z-index: 300;
  min-width: 240px;
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #dcdcde;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 445px;
  overflow: auto;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  max-width: 280px;
  display: none;

  &:focus-visible {
    outline: none;
  }

  &.open {
    display: block;
  }
`;

const FilterDropDown = styled.ul`
  margin: 0;
  padding: 0;
`;

const FilterDropdownItem = styled.li`
  display: block;
  text-align: left;
  list-style: none;
  cursor: pointer;
  &.selected,
  &:hover {
    background-color: var(--dts_withe_background);
  }
`;

const FilterDropdownItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
`;

const FilterLabelConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    .filter-label {
      background-color: var(--dts_light_grey);
    }
  }
`;

const FilterValueContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--dts_withe_background);
  border-radius: 0 2px 2px 0;
  margin-right: 4px;
  padding-right: 4px;
`;
export default FilterBar;
