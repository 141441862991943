import { ExtensionRepository } from '../../../domain/worktype/extension/ExtensionRepository';
import UpdateWorkTypeExtensionInfo from '../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import UpdateTeethTypeExtensionInfo from '../../../domain/worktype/extension/UpdateTeethTypeExtensionInfo';

type UpdateWorktypesRepository = Pick<
  ExtensionRepository,
  'updateExtension' | 'updateTeethExtension'
>;

const updateExtensionUseCase = (
  repo: UpdateWorktypesRepository,
  extension: UpdateWorkTypeExtensionInfo,
) => {
  console.log('Here we go again updateExtensionUseCase');
  return repo.updateExtension(extension);
};

const updateTeethExtensionUseCase = (
  repo: UpdateWorktypesRepository,
  extension: UpdateTeethTypeExtensionInfo,
) => {
  console.log('Here we go again updateTeethExtensionUseCase');
  return repo.updateTeethExtension(extension);
};

export { updateExtensionUseCase, updateTeethExtensionUseCase };
export type { UpdateWorktypesRepository };
