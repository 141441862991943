import React, { useContext } from 'react';
import styled from 'styled-components';
import { WorkSheetStatus } from './WorkSheetStatus';
import { CaseWorkSheetToothPanoramaComponent } from './teethpanorama/CaseWorkSheetToothPanoramaComponent';
import { CaseDetailContext } from '../CaseDetailProvider';
import { WorkTypeUiModel } from '../../ui-model/worktype/WorkTypeUiModel';
import { Extras } from './Extras';
import { OvalButton } from '../../components/dsm/buttons/OvalButton';
import { ClientUiModel } from '../../ui-model/user-management/ContactUiModel';
import ClientAndPatientInfo from './ClientAndPatientInfo';
import {
  setCaseDetailLabWork,
  setCaseDetailRemoveLabWork,
  setCaseDetailSelectedLabwork,
  setCaseDetailSetExtras,
  setCaseDetailSetToothExtras,
} from '../../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import { Extra, mapExtrasFromExtensionList } from '../../../domain/Extra';
import { OvalButtonDualLableRemovable } from '../../components/dsm/buttons/OvalButtonDualLableRemovable';
import { SelectedTemplate } from '../../ui-model/CaseDetail';
import {
  SELECTABLE_EXTENSION,
  SIMPLE_EXTENSION,
} from '../../../domain/worktype/extension/Extension';
import { DRAFT } from '../caseStates';
import Dialog, { DialogProps } from '../../components/generic/Dialog';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../../components/generic/ConfirmationDialog';
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg';
import { ExtensionBox } from './ExtensionBox';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { useAuth } from '../../../routeFiles/AuthContext';

export interface CaseWorkSheetCompProps {
  workTypes: WorkTypeUiModel[];
  clients: ClientUiModel[];
  labWorkStatus: LabworkStatus;
}

export type LabworkStatus = {
  title: string;
  description: string;
  altDescription?: string;
  icon: number;
};

export type WorkTypeModal = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  workTypes: DialogProps['options'];
  onOptionClick: DialogProps['onOptionClick'];
};

const InitialWorkTypeModal: WorkTypeModal = {
  isOpen: false,
  onClose: () => {},
  workTypes: [],
  title: LANGUAGE_KEYS.SELECT_WORKTYPE,
  onOptionClick: () => {},
};

export function CaseWorkSheetComp(props: CaseWorkSheetCompProps) {
  const { caseDetailObj, validSubscription } = useContext(CaseDetailContext);
  const dispatch = useDispatch();
  const { isTabletSmall } = useDeviceParameters();
  const translate = useTranslate();

  const { userRole } = useAuth();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  const InitialDeleteDialog: ConfirmationDialogProps = {
    isOpen: false,
    title: translate(LANGUAGE_KEYS.DELETE_LABWORK_TITLE),
    description: translate(LANGUAGE_KEYS.DELETE_LABWORK_DESCRIPT),
    onClose: () => {},
    negativeButton: { label: translate(LANGUAGE_KEYS.CANCEL), onClick: () => {} },
    positiveButton: { label: translate(LANGUAGE_KEYS.REJECT), onClick: () => {} },
  };

  function setSelectedLabWork(selectedLabwork: number) {
    dispatch(setCaseDetailSelectedLabwork(selectedLabwork));
  }

  function addNewLabwork(workTypeId: number, categoryId: number) {
    const currentLabworksASC = [...caseDetailObj.labworks].sort((a, b) =>
      a.labWorkId > b.labWorkId ? 1 : -1,
    );
    const newLabWorkId =
      currentLabworksASC.length === 0
        ? 1
        : currentLabworksASC[currentLabworksASC.length - 1].labWorkId + 1;
    const workType = props.workTypes.find((v) => {
      return v.id === workTypeId;
    })!;
    const newLabwork = {
      labWorkId: newLabWorkId,
      workType: JSON.parse(JSON.stringify(workType)),
      extensions: [],
      selectedTemplate: { kind: 'Existing', id: 0 } as SelectedTemplate,
      extras: mapExtrasFromExtensionList(
        props.workTypes.find((workType) => workType.id === workTypeId)?.extensionList || [],
        [],
        [SELECTABLE_EXTENSION, SIMPLE_EXTENSION],
      ),
    };

    newLabwork.workType.selectedCategory = categoryId;

    dispatch(setCaseDetailLabWork(newLabwork));
    dispatch(setCaseDetailSelectedLabwork(newLabWorkId));
  }

  function removeLabWork(labWorkId: number) {
    dispatch(setCaseDetailRemoveLabWork(labWorkId));
  }

  const [workTypeModal, setWorkTypeModal] = React.useState<WorkTypeModal>(InitialWorkTypeModal);

  const [deleteDialog, setDeleteDialog] =
    React.useState<ConfirmationDialogProps>(InitialDeleteDialog);

  const closeDeleteDialog = () => setDeleteDialog({ ...deleteDialog, isOpen: false });

  const closeWorkTypeModal = () => setWorkTypeModal(InitialWorkTypeModal);

  const openWorkTypeDialog = () =>
    setWorkTypeModal({
      isOpen: true,
      title: translate(LANGUAGE_KEYS.SELECT_WORKTYPE),
      onClose: closeWorkTypeModal,
      workTypes: [
        {
          // title: 'Technician',
          options: props.workTypes
            .filter((wt) => wt.active === 1)
            .map((workType) => {
              return { key: workType.id, label: workType.name };
            }),
        },
      ],
      onOptionClick: (key) => openWorkTypeCategoryDialog(Number(key)),
    });

  const openWorkTypeCategoryDialog = (workTypeId: number) => {
    const workType = props.workTypes.find((workType) => workType.id === workTypeId);
    if (workType && workType.category && workType.category.length > 0) {
      setWorkTypeModal({
        isOpen: true,
        title: workType.name || '',
        onClose: closeWorkTypeModal,
        workTypes: [
          {
            options:
              workType.category
                .filter((category) => category.active == true)
                .map((workTypeCategory) => {
                  return { key: workTypeCategory.id, label: workTypeCategory.name };
                }) || [],
          },
        ],
        onOptionClick: (key) => {
          addNewLabwork(workType.id, Number(key));
          closeWorkTypeModal();
        },
      });
    } else {
      workType && addNewLabwork(workType.id, -1);
      closeWorkTypeModal();
    }
  };

  function showConfirmRemoveLabWorkDialog(onConfirm: () => void) {
    setDeleteDialog({
      ...deleteDialog,
      isOpen: true,
      negativeButton: { label: translate(LANGUAGE_KEYS.CANCEL), onClick: closeDeleteDialog },
      positiveButton: {
        label: translate(LANGUAGE_KEYS.REJECT),
        onClick: () => {
          closeDeleteDialog();
          onConfirm();
        },
      },
      onClose: closeDeleteDialog,
    });
  }

  function removeLabWorkClick(labWorkId: number) {
    if (validSubscription) {
      showConfirmRemoveLabWorkDialog(() => removeLabWork(labWorkId));
    } else {
      showPaymentExpireModal(userRole);
    }
  }

  const handleLabworkExtraChange = (extras: Array<Extra>) => {
    if (validSubscription) {
      dispatch(setCaseDetailSetExtras(extras));
    } else {
      showPaymentExpireModal(userRole);
    }
  };

  const handleTeethExtraChange = (extras: Array<Extra>) => {
    if (validSubscription) {
      dispatch(setCaseDetailSetToothExtras(extras));
    } else {
      showPaymentExpireModal(userRole);
    }
  };

  return (
    <CaseWorkSheet>
      <ClientAndPatientInfo />
      <ContentContainer>
        <CaseWorkSheetHeader>
          {caseDetailObj !== null &&
            caseDetailObj.labworks !== null &&
            caseDetailObj.labworks.map((value) => {
              return (
                <OvalButtonDualLableRemovable
                  key={value.labWorkId}
                  onClick={() => setSelectedLabWork(value.labWorkId)}
                  removeClick={
                    !isTabletSmall
                      ? caseDetailObj.workSheetEditable
                        ? () => removeLabWorkClick(value.labWorkId)
                        : () => {}
                      : () => {}
                  }
                  text={value?.workType?.name}
                  secondText={
                    value?.workType?.category.find((c) => {
                      return c.id === value?.workType?.selectedCategory;
                    })?.name
                  }
                  selected={
                    caseDetailObj !== null && caseDetailObj.selectedLabWork === value.labWorkId
                  }
                  removable={!isTabletSmall ? caseDetailObj.workSheetEditable : false}
                />
              );
            })}
          {!isTabletSmall ? (
            caseDetailObj !== null && caseDetailObj.workSheetEditable ? (
              <OvalButton
                onClick={() => {
                  if (validSubscription) {
                    openWorkTypeDialog();
                  } else {
                    showPaymentExpireModal(userRole);
                  }
                }}
                text={translate(LANGUAGE_KEYS.ADD_NEW)}
              />
            ) : null
          ) : null}
        </CaseWorkSheetHeader>
        <CaseWorkSheetBody>
          <CaseWorkSheetBodySideContainer>
            {caseDetailObj !== null &&
            caseDetailObj.selectedTeeth !== null &&
            caseDetailObj.selectedTeeth.teeth > -1 &&
            caseDetailObj.selectedTeeth.quarter > -1 &&
            !isTabletSmall ? (
              <Extras
                title={`${translate(LANGUAGE_KEYS.TOOTH)} ${caseDetailObj.selectedTeeth.quarter}.${
                  caseDetailObj.selectedTeeth.teeth
                }`}
                extras={
                  [...caseDetailObj.tooths.upper, ...caseDetailObj.tooths.lower].find((t) => {
                    return (
                      t.quarter === caseDetailObj.selectedTeeth.quarter &&
                      t.teethp === caseDetailObj.selectedTeeth.teeth
                    );
                  }).ext || []
                }
                onChange={handleTeethExtraChange}
                disabled={caseDetailObj.status !== DRAFT}
              />
            ) : (
              !isTabletSmall && <WorkSheetStatus labWorkStatus={props.labWorkStatus} />
            )}
          </CaseWorkSheetBodySideContainer>
          <div
            style={{
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <div style={{ width: '600px', marginLeft: '-20px' }}>
              <CaseWorkSheetToothPanoramaComponent />
            </div>
            {caseDetailObj !== null &&
              caseDetailObj.labworks !== null &&
              caseDetailObj.labworks.length > 0 &&
              caseDetailObj.labworks.map((labWork, i) => {
                if (labWork.labWorkId === caseDetailObj.selectedLabWork) {
                  return <ExtensionBox key={i} />;
                }
              })}
          </div>
          {!isTabletSmall && (
            <CaseWorkSheetBodySideContainer>
              {caseDetailObj !== null && caseDetailObj.selectedLabWork >= 0 && (
                <Extras
                  title={translate(LANGUAGE_KEYS.ADD_EXTRAS)}
                  extras={
                    (caseDetailObj.labworks !== null &&
                      caseDetailObj.labworks.find((lw) => {
                        return lw.labWorkId === caseDetailObj.selectedLabWork;
                      })?.extras) ||
                    []
                  }
                  onChange={handleLabworkExtraChange}
                  disabled={caseDetailObj.status !== DRAFT}
                />
              )}
            </CaseWorkSheetBodySideContainer>
          )}
        </CaseWorkSheetBody>
        <ConfirmationDialog
          isOpen={deleteDialog.isOpen}
          title={deleteDialog.title}
          description={deleteDialog.description}
          negativeButton={deleteDialog.negativeButton}
          positiveButton={deleteDialog.positiveButton}
          onClose={deleteDialog.onClose}
          icon={<DeleteIcon />}
        />
        <Dialog
          isOpen={workTypeModal.isOpen}
          onClose={workTypeModal.onClose}
          title={translate(workTypeModal.title)}
          options={workTypeModal.workTypes}
          onOptionClick={workTypeModal.onOptionClick}
          containerStyle={{ width: '240px' }}
        />
      </ContentContainer>
    </CaseWorkSheet>
  );
}

const ContentContainer = styled.div`
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  &:hover {
    border: 1px solid var(--dts_default_blue);
  }
`;

const CaseWorkSheet = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const CaseWorkSheetHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

const CaseWorkSheetBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0;
`;

const CaseWorkSheetBodySideContainer = styled.div`
  justify-self: center;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px 400px 8px;
  overflow: hidden;
  // combined with padding-bottom 400px - overflow-y visible for dropdown
  margin-bottom: -400px;
  box-sizing: border-box;
`;
