import React, { FC, useState } from 'react';
import { Text, TextType } from '../../../../../components/dsm/Text';
import styled from 'styled-components';

import { TeethImage } from '../TeethImage';
import { InputTextNumber } from '../../../../../components/dsm/InputTextNumber';
import { Checkbox } from '../../../../../components/dsm/Checkbox';
import Extension from '../../../../../ui-model/worktype/extension/Extension';

export type ExtensionRowProps = {
  extensionModel: Extension;
  index: number;
  handleChangeExtension: (extension: Extension) => void;
};

export const ExtensionRow: FC<ExtensionRowProps> = (extensionRowProps) => {
  const [checked, setchecked] = useState(extensionRowProps.extensionModel.active);

  const handleOnCheckboxClick = (index: number, active: boolean) => {
    setchecked(active);
    extensionRowProps.extensionModel.active = active;
    extensionRowProps.handleChangeExtension(extensionRowProps.extensionModel);
  };

  const handlePriceChange = (value: string) => {
    if (value == '') value = '0';
    extensionRowProps.extensionModel.price = parseFloat(value);
    extensionRowProps.handleChangeExtension(extensionRowProps.extensionModel);
  };

  return (
    <ExtensionRowContainer className={`${!checked && 'inactive'}`}>
      <ExtensionNameTeethsContainer>
        <NameContainer className='name'>
          <Text type={TextType.BODY_2_BOLD}>{extensionRowProps.extensionModel.name}</Text>
        </NameContainer>
        <TeethContainer>
          {extensionRowProps.extensionModel?.teethIds.map((teeth: number) => (
            <TeethImage key={teeth} typeTeeth={teeth} width={22} height={36} isCustomSize={false} />
          ))}
        </TeethContainer>
      </ExtensionNameTeethsContainer>
      <ExtensionPriceStateContainer>
        <PriceContainer>
          <Price>
            <InputTextNumber
              placeHolder='00'
              width='100%'
              initValue={
                extensionRowProps.extensionModel.price === null
                  ? '00'
                  : extensionRowProps.extensionModel.price + ''
              }
              onChange={handlePriceChange}
              pattern='[0-9]*[.]?[0-9]*'
            />
          </Price>
          <Separator></Separator>
          <Currency>
            <Text type={TextType.CAPTION_REGULAR}>Ron</Text>
          </Currency>
        </PriceContainer>
        <StateContainer>
          <Checkbox
            checkboxId={extensionRowProps.index}
            onClick={handleOnCheckboxClick}
            active={checked}
            disabled={true}
          />
        </StateContainer>
      </ExtensionPriceStateContainer>
    </ExtensionRowContainer>
  );
};

const ExtensionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 24px;
  margin-bottom: 1px;
  gap: 3px;
  background: var(--dts_white);
  border-radius: 3px;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-in-out;

  .name,
  span {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-in-out;
  }

  &.inactive {
    opacity: 0.8;
    .name,
    span {
      opacity: 0.5;
    }
    input {
      pointer-events: none;
    }
  }

  @media (max-width: 1300px) {
    padding: 8px 14px 8px 14px;
  }
  @media (max-width: 765px) {
    padding: 8px 10px 8px 10px;
  }
`;
const ExtensionNameTeethsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 89%;
  gap: 3px;

  @media (max-width: 1300px) {
    flex-basis: 80%;
  }
  @media (max-width: 765px) {
    flex-basis: 70%;
  }
`;
const ExtensionPriceStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 11%;
  gap: 3px;
  @media (max-width: 1300px) {
    flex-basis: 20%;
  }
  @media (max-width: 765px) {
    flex-basis: 30%;
  }
`;
const NameContainer = styled.div`
  flex-basis: 25.3%;
  @media (max-width: 1300px) {
    flex-basis: 40%;
  }
`;
const TeethContainer = styled.div`
  flex-basis: 74.7%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    flex-basis: 60%;
  }
`;
const PriceContainer = styled.div`
  flex-basis: 60%;
  padding: 7px 8px 7px 8px;
  border: 1px solid var(--dts_light_grey);
  background: var(--dts_white);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;
const StateContainer = styled.div`
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;
`;

const Price = styled.span`
  flex-basis: 51%;
  line-height: 12px;
`;

const Separator = styled.span`
  height: 16px;
  border-right: 1px solid;
  opacity: 0.5;
`;
const Currency = styled.span`
  opacity: 0.5;
`;
