import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCaseRepositoryImplementation } from '../../data/cases/CaseRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../../data/worktype/WorkTypeRepositoryImplementation';
import { useCaseDetailViewModel } from './CaseDetailViewModel';
import { useParams } from 'react-router';
import { useExtensionRepositoryImplementation } from '../../data/worktype/extension/ExtensionRepositoryImplementation';
import { useDispatch } from 'react-redux';
import {
  resetCaseDetail,
  resetStatusNotiState,
  setCaseDetail,
} from '../../data/cases/detail/caseDetailReducer';
import {
  useContactsRepositoryImplementation,
  useCompaniesRepositoryImplementation,
} from '../../data/user-management/UsersRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { AlertType } from '../../domain/alert/AlertManager';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';
import { useAuth } from '../../routeFiles/AuthContext';
import { CaseDetail } from '../ui-model/CaseDetail';
import store from '../../data/utils/store';
import { RESET_BE_CASE } from '../../data/cases/casesActionTypes';

export const CaseDetailContext = React.createContext({} as any);

export function CaseDetailProvider(props: { children }) {
  const { id } = useParams();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const repo = useCaseRepositoryImplementation();
  const wtRepo = useWorkTypeRepositoryImplementation();
  const extensionsRepo = useExtensionRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const invoiceRepo = useInvoiceRepositoryImplementation();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const { userRole, validSubscription } = useAuth();

  const {
    contacts,
    extensions,
    caseDetail,
    workTypes,
    workTypesUi,
    clientsUi,
    updateCase,
    updateDescription,
    getCaseById,
    getCompaniesName,
    isEnabledReportsOverview,
    isEnabledInvoice,
    isEnabledCaseStatusAction,
    deleteCase,
    closeReopenCase,
    getInvoiceByCaseId,
  } = useCaseDetailViewModel(
    id!,
    userRole,
    repo,
    wtRepo,
    extensionsRepo,
    contactRepo,
    companiesRepo,
    featureRepo,
    invoiceRepo,
  );

  const [caseDetailObj, setCaseDetailObj] = useState<CaseDetail | null>(caseDetail);
  const [invoiceNumber, setInvoiceNumber] = useState<number>(-1);

  let currentValue: CaseDetail | null = null;

  function handleChange() {
    let isMounted = true;
    const previousValue: CaseDetail | null = currentValue;
    currentValue = store.getState().casesDetail.caseUi;
    if (previousValue !== currentValue) {
      if (isMounted) {
        setCaseDetailObj(currentValue);
      }
    }
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    getCaseById(Number(id));
    setInvoiceNumber(getInvoiceByCaseId(Number(id)));
  }, []);

  useEffect(() => {
    dispatch(setCaseDetail(JSON.parse(JSON.stringify(caseDetail))));
  }, [repo.caseBe, contacts, extensions, workTypes]);

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = store.subscribe(handleChange);
    return () => {
      if (isMounted) {
        unsubscribe();
        dispatch(resetCaseDetail());
      }
      isMounted = false;
    };
  }, [dispatch, store]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BE_CASE });
    };
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentValue = store.getState().casesDetail.stackOfChanges;
      const loading = store.getState().casesDetail.loading;
      if (!loading && currentValue.length > 0) {
        updateCase().then((resp) => {
          if (!resp) {
            showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
          } else {
            if (store.getState().casesDetail.needStatusChangeNoti) {
              showAlert(translate(LANGUAGE_KEYS.CHANGED_PHASE_STATUS), AlertType.SUCCESS);
            }
          }
          dispatch(resetStatusNotiState());
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const wt = workTypesUi;

  return (
    <CaseDetailContext.Provider
      value={{
        caseDetailObj,
        repo,
        wt,
        clientsUi,
        updateDescription,
        getCompaniesName,
        isEnabledReportsOverview,
        isEnabledInvoice,
        isEnabledCaseStatusAction,
        deleteCase,
        closeReopenCase,
        validSubscription,
        invoiceNumber,
      }}
    >
      {props.children}
    </CaseDetailContext.Provider>
  );
}
