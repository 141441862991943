import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../../icons/arrowDown.svg';
import { Text, TextType } from './Text';
import Tooltip from './Tooltip';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';

type SelectProps = {
  values: SelectValues;
  selected?: SelectedValue;
  onChange: (value: SelectedValue) => void;
  placeHolder: string;
  focusedPlaceHolder?: string;
  resetOnClick?: boolean;
  textEditable?: boolean;
  disabled?: boolean;
  size?: 'Small' | 'Medium';
  validSubscription: boolean;
  userRole: number | null;
};

export type SelectValues = Array<Omit<SelectedValue, 'id'> & { id: number }>;
export type SelectedValue = {
  id?: number;
  label: string;
  clientCompanyId?: number;
  clientCompanyName?: string;
};

// TODO: small, medium(paddings)
export const Select = (props: SelectProps) => {
  const {
    values,
    selected,
    onChange,
    placeHolder,
    focusedPlaceHolder = placeHolder,
    resetOnClick,
    textEditable = true,
    disabled,
    size = 'Medium',
    validSubscription,
    userRole,
  } = props;

  const [filteredValues, setFilteredValues] = useState<SelectProps['values']>(props.values);
  const [isOpen, setIsOpen] = useState(false);

  const [writeValue, setWriteValue] = useState<string | null>(null);

  const optionRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  /**
   * Update filtered list on "values" or "selected" change and call onChange
   */
  useEffect(() => {
    setFilteredValues(values);
  }, [writeValue]);

  useEffect(() => {
    optionRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [isOpen, optionRefs]);

  const handleInputChange = (input) => {
    textEditable &&
      onChange(
        filteredValues.find((value) => value.label === input.target.value) || {
          label: input.target.value,
        },
      );
    setWriteValue(input.target.value);
  };

  const handleOptionClick = (value) => {
    onChange(value);
    if (value.clientCompanyName !== undefined) {
      setWriteValue(value.label + ' ' + value.clientCompanyName);
    } else {
      setWriteValue(value.label);
    }
    setIsOpen(false);
  };

  const renderOptions = useMemo(() => {
    return (
      <React.Fragment>
        {filteredValues.length ? (
          <OptionsContainer className={size}>
            <Options className='custom-scrollbar'>
              {filteredValues.map((value, index) => {
                return (
                  <Tooltip
                    key={index}
                    content={value.label}
                    disabled={!visibleTooltips.includes(index)}
                  >
                    <Option
                      className={
                        selected?.id === value.id &&
                        selected?.clientCompanyId === value.clientCompanyId
                          ? 'selected'
                          : ''
                      }
                      onMouseDown={() => handleOptionClick(value)}
                      key={value.id}
                    >
                      <CustomSelectText
                        ref={(el) => {
                          if (el) optionRefs.current[index] = el;
                        }}
                        type={TextType.CAPTION_BOLD}
                        ellipsis={true}
                      >
                        {value.label} <CompanyName>{value.clientCompanyName}</CompanyName>
                      </CustomSelectText>
                    </Option>
                  </Tooltip>
                );
              })}
            </Options>
          </OptionsContainer>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }, [filteredValues]);

  return (
    <Container className={disabled ? 'disabled' : ''}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Input
          className={`${!textEditable && 'not-editable'} ${disabled && 'disabled'}`}
          type='text'
          value={writeValue == null ? selected?.label : writeValue}
          placeholder={isOpen ? focusedPlaceHolder : placeHolder}
          onChange={handleInputChange}
          onClick={() => {
            if (validSubscription !== null && validSubscription) {
              resetOnClick && onChange({ label: '' });
            } else {
              showPaymentExpireModal(userRole);
            }
          }}
          onFocus={() => {
            if (validSubscription !== null && validSubscription) {
              setIsOpen(true);
            }
          }}
          onBlur={() => setIsOpen(false)}
          disabled={disabled}
        />
        {!textEditable && <DropdownIconStyled />}
      </div>
      {!disabled && isOpen && renderOptions}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  cursor: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  overflow: visible;
`;

const Input = styled.input`
  width: 100%;
  color: var(--dts_black);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  text-overflow: ellipsis;
  padding-right: 12px;
  &.not-editable {
    caret-color: transparent;
    cursor: pointer;
  }
  :focus {
    outline: none;
    ::-webkit-input-placeholder {
      opacity: 0.5;
    }
  }
  ::-webkit-input-placeholder {
    color: var(--dts_black);
    opacity: 0.7;
    text-overflow: ellipsis;
  }
  &.disabled {
    background-color: initial;
    cursor: not-allowed;
    ::-webkit-input-placeholder {
      opacity: 0.5;
    }
  }
`;

const DropdownIconStyled = styled(DropdownIcon)`
  display: block;
  width: 8px;
  height: 8px;
  padding-left: 4px;
  margin-left: -12px !important;
  pointer-events: none;
`;

const OptionsContainer = styled.div`
  width: 100%;
  min-width: 120px;
  max-width: 240px;
  position: absolute;
  box-sizing: border-box;
  padding: 24px;
  background-color: var(--dts_white);
  box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  cursor: initial;
  z-index: 1000 !important;

  &.Small {
    padding: 16px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 4px;
  max-height: 316px;
  flex-shrink: 0;
`;

const Option = styled.div`
  flex-shrink: 0;
  background-color: var(--dts_white);
  padding: 6px 8px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.selected,
  :hover {
    background-color: var(--dts_withe_background);
  }
`;

const CustomSelectText = styled(Text)`
  display: flex;
  gap: 8px;
`;

const CompanyName = styled.span`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export default Select;
