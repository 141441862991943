import { getCountiesService } from './CountyService';
import * as actionTypes from './countyActionTypes';

const getCountiesAction = () => (dispatch: any) => {
  const resp = getCountiesService();
  dispatch({ type: actionTypes.SAVE_COUNTY, counties: resp });
  return resp;
};

export { getCountiesAction };
