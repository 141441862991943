import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text, TextColor, TextType } from '../components/dsm/Text';
import { RoundButton } from '../components/dsm/buttons/RoundButton';
import { useDashboardContext } from './DashboardContext';
import useTranslate from '../translations/useTranslate';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrowDown.svg';
import { useFilters } from '../hooks/FilterContext';
import { useNavigationType } from 'react-router-dom';

function DashboardFilters(props: {
  filters: {
    label: string;
    filterType: number;
    indicator: boolean;
    elementNr: any;
  }[];
}) {
  const { menuSelection, updateMenuSelection } = useDashboardContext();
  const translate = useTranslate();

  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const { isMobile } = useDeviceParameters();

  const [selectFilter, setSelectFilter] = useState<{
    label: string;
    filterType: number;
    indicator: boolean;
    elementNr: any;
  } | null>(null);

  const { dashboardFilters, setDeshboardFilters } = useFilters();
  const navigationType = useNavigationType();

  useEffect(() => {
    setSelectFilter(dashboardFilters);
    if (dashboardFilters === null && props.filters.length > 0) {
      clickFilter(props.filters[0].filterType);
      setDeshboardFilters(props.filters[0]);
    }
  }, [props.filters]);

  useEffect(() => {
    if (navigationType === 'POP') {
      setIsBackNavigation(true);
      if (dashboardFilters !== undefined && dashboardFilters !== null) {
        clickFilter(dashboardFilters.filterType);
      }
    }
  }, [navigationType]);

  useEffect(() => {
    if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      if (!isBackNavigation) {
        setDeshboardFilters(null);
      }
    }
  }, [navigationType, isBackNavigation, setDeshboardFilters]);

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {!isMobile &&
        props.filters?.map((filter, index) => {
          return (
            <FilterContent key={index}>
              <RoundButton
                onClick={() => {
                  setDeshboardFilters(filter);
                  clickFilter(filter.filterType);
                }}
                indicator={filter.indicator}
                buttonState={checkState(filter.filterType)}
                textType={TextType.BODY_2_BOLD}
              >
                {translate(filter.label)} ({filter.elementNr})
              </RoundButton>
            </FilterContent>
          );
        })}
      {isMobile && (
        <MobileDropDownFilterContainer tabIndex={0} onBlur={() => setExpanded(false)}>
          <MobileDropDownFilter onClick={() => setExpanded(!expanded)}>
            <Text type={TextType.BODY_2_BOLD} clr={TextColor.WHITE} ellipsis={true}>
              {selectFilter !== null ? translate(selectFilter.label) : ''}
            </Text>
            <ArrowContainer>
              <CustomArrowIcon className={`${expanded && 'open'}`} />
            </ArrowContainer>
          </MobileDropDownFilter>
          <MobileDropDownFilterItems className={`${expanded && 'open'}`}>
            {props.filters?.map((filter, index) => {
              return (
                <LabelText
                  key={index}
                  type={TextType.BODY_2}
                  ellipsis={true}
                  onClick={() => {
                    setSelectFilter(filter);
                    setExpanded(false);
                    clickFilter(filter.filterType);
                  }}
                >
                  {translate(filter.label)}
                </LabelText>
              );
            })}
          </MobileDropDownFilterItems>
        </MobileDropDownFilterContainer>
      )}
    </>
  );

  function checkState(state: number) {
    if (menuSelection === state) return 0;
    return 1;
  }

  function clickFilter(state: number) {
    if (menuSelection !== state) {
      updateMenuSelection(state);
    }
  }
}

const FilterContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const MobileDropDownFilterContainer = styled.div``;

const MobileDropDownFilter = styled.span`
  background: var(--dts_black);
  border-radius: 45px;
  display: flex;
  padding: 8px 16px;
  max-width: 105px;
  align-items: center;
  gap: 8px;
`;

const ArrowContainer = styled.span`
  flex-grow: 0;
  line-height: 0;
`;

const CustomArrowIcon = styled(ArrowDownIcon)`
  padding: 5px;
  &.open {
    transform: rotate(180deg);
  }

  path {
    fill: var(--dts_white);
  }
`;

const MobileDropDownFilterItems = styled.div`
  display: none;
  flex-direction: column;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
  background-color: var(--dts_white);
  border-radius: 4px;
  &.open {
    display: flex;
    max-height: 200px;
    min-width: 200px;
    transition: max-height 0.3s ease-in;
    box-shadow: -1px 2px 11px 0px #00000024;
    padding: 5px;
  }
  position: absolute;
  z-index: 9999;
`;

const LabelText = styled(Text)`
  padding: 5px;
`;
export default DashboardFilters;
