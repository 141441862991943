import data from './countries.json';

function getCountiesService(): Array<{ auto: string; nume: string }> {
  return data
    .sort((a, b) => (a.nume > b.nume ? 1 : -1))
    .map((item) => {
      return { auto: item.auto, nume: item.nume };
    });
}

export { getCountiesService };
