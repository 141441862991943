import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import styled from 'styled-components';

import WorktypeExtensionRowHeader from '../components/worktypeitem/extensions/ExtensionRowHeader';
import { ExtensionRow } from '../components/worktypeitem/extensions/ExtensionRow';
import { useExtensionViewModel } from './ExtensionViewModel';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { useExtensionRepositoryImplementation } from '../../../../data/worktype/extension/ExtensionRepositoryImplementation';
import Extension from '../../../ui-model/worktype/extension/Extension';
import ExtensionInfo from '../../../ui-model/worktype/extension/ExtensionInfo';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

function Extensions() {
  const { id } = useParams();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const extensionRepo = useExtensionRepositoryImplementation();
  const { getSubategoryByWorkTypeId, getTeethExtensionsByWorkTypeId, updateExtension } =
    useExtensionViewModel(workTypeRepo, extensionRepo);

  const [extensionSubcategory, setExtensionSubcategory] = useState<Array<ExtensionInfo>>([]);

  useEffect(() => {
    setExtensionSubcategory(getSubategoryByWorkTypeId(id !== undefined ? parseInt(id) : -1));
  }, [workTypeRepo.workTypes]);

  const [teethExtensions, setTeethExtensions] = useState<Array<ExtensionInfo>>([]);

  useEffect(() => {
    setTeethExtensions(getTeethExtensionsByWorkTypeId(id !== undefined ? parseInt(id) : -1));
  }, [workTypeRepo.workTypes]);

  const handleChangeExtension = (extension: Extension) => {
    let groupId = -1;
    extensionSubcategory.forEach((extItem) => {
      const isFound = extItem.extensions.find((ext) => {
        return ext.id === extension.id;
      });
      if (isFound) groupId = extItem.groupId;
    });
    updateExtension(id !== undefined ? parseInt(id) : -1, groupId, extension);
  };

  const handleChangeTeethExtension = (extension: Extension) => {
    let groupId = -1;
    teethExtensions.forEach((extItem) => {
      const isFound = extItem.extensions.find((ext) => {
        return ext.id === extension.id;
      });
      if (isFound) groupId = extItem.groupId;
    });
    updateExtension(id !== undefined ? parseInt(id) : -1, groupId, extension);
  };

  return (
    <ExtensionsContainer>
      <WorktypeExtensionRowHeader
        firstColumn={LANGUAGE_KEYS.WORKTYPE_COMPONENTS}
        secondColumn={LANGUAGE_KEYS.PRICE}
        thirdColumn={LANGUAGE_KEYS.STATE}
      />
      {extensionSubcategory?.map((category, index) => {
        return (
          <CategoryContainer key={index}>
            <ExtensionList
              extensionList={category.extensions}
              handleChangeExtension={handleChangeExtension}
            />
          </CategoryContainer>
        );
      })}
      {teethExtensions.length > 0 && (
        <>
          <WorktypeExtensionRowHeader
            firstColumn={LANGUAGE_KEYS.TOOTHTYPE_COMPONENTS}
            secondColumn={null}
            thirdColumn={null}
          />
          {teethExtensions?.map((category, index) => {
            return (
              <CategoryContainer key={index}>
                <ExtensionList
                  extensionList={category.extensions}
                  handleChangeExtension={handleChangeTeethExtension}
                />
              </CategoryContainer>
            );
          })}
        </>
      )}
    </ExtensionsContainer>
  );
}

function ExtensionList(props: {
  extensionList: Extension[] | undefined;
  handleChangeExtension: (extension: Extension) => void;
}) {
  return (
    <>
      {props.extensionList !== undefined &&
        buildExtensionList(
          props.extensionList?.filter((value) => {
            return value;
          }),
          props.handleChangeExtension,
        )}
    </>
  );
}

function buildExtensionList(
  extensionList: Extension[] | undefined,
  handleChangeExtension: (extension: Extension) => void,
) {
  const extensionRowView: any[] = [];
  extensionList?.map(function (e, i) {
    extensionRowView.push(
      <ExtensionRow
        extensionModel={e}
        index={i}
        key={e.id}
        handleChangeExtension={handleChangeExtension}
      />,
    );
  });
  return extensionRowView;
}

const CategoryContainer = styled.div`
  // margin-bottom: 8px;
`;

const ExtensionsContainer = styled.div`
  margin-top: 24px;
`;

export default Extensions;
