import React, { useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../components/dsm/Text';
import InputField from '../../components/dsm/InputField';
import FormAction from '../components/FormAction';
import { useNavigate } from 'react-router';
import {
  InputFieldErrorType,
  InputFieldType,
  getInputErrorText,
  isValidInputField,
} from '../components/FieldUtils';
import { useSignUpFlowViewModel } from './SignUpFlowViewModel';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { MobileBackButton } from '../../components/generic/MobileBackButton';
import { DeviceSizeProps } from '../../DeviceInformations';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';

const loginFields = [
  {
    labelText: LANGUAGE_KEYS.EMAIL_ADDRESS,
    labelFor: 'email-address',
    id: 'email-address',
    name: 'email',
    type: 'email',
    autoComplete: 'email',
    isRequired: true,
    placeholder: 'dentech@dentech.ro',
  },
  {
    labelText: LANGUAGE_KEYS.PASSWORD,
    labelFor: 'password',
    id: 'password',
    name: 'password',
    type: 'password',
    autoComplete: 'current-password',
    isRequired: true,
    placeholder: LANGUAGE_KEYS.PASSWORD,
  },
];

const Login = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();
  const authRepo = useAuthRepositoryImplementation();
  const { authenticateUser } = useSignUpFlowViewModel(authRepo);
  const fields = loginFields;
  const fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ''));
  const [loginState, setLoginState] = useState(fieldsState);
  const [errorText, setErrorText] = useState<string>('');

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = loginState['email-address'];
    const password = loginState['password'];
    if (
      email !== null &&
      password !== null &&
      isValidInputField(InputFieldType.EMAIL_ADDRESS, email) &&
      isValidInputField(InputFieldType.PASSWORD, password)
    ) {
      authUser(email, password);
    } else {
      setErrorText(translate(getInputErrorText(InputFieldErrorType.INVALID_EMAIL_RESET_PASSWORD)));
    }
  };

  const authUser = (email: string, password: string) => {
    authenticateUser(email, password).then((resp) => {
      if (!resp.result) {
        setErrorText(translate(getInputErrorText(InputFieldErrorType.INVALID_FIELDS_LOGIN)));
      } else {
        if (resp.currentUrl !== null) {
          window.location.href = resp.currentUrl;
        } else {
          navigate('/');
        }
      }
    });
  };

  return (
    <>
      {isMobile && <MobileBackButton />}
      <LoginContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <TitleText type={TextType.TITLE_BOLD_2}>{translate(LANGUAGE_KEYS.LOG_IN)}</TitleText>
          <CustomForm>
            {errorText !== '' && <ErrorText type={TextType.BODY_2}>{errorText}</ErrorText>}
            <InputFields>
              {loginFields.map((field) => {
                return (
                  <InputField
                    key={field.id}
                    handleChange={handleChange}
                    value={loginState[field.id]}
                    labelText={translate(field.labelText)}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={translate(field.placeholder)}
                    error={errorText !== ''}
                    autoComplete={field.autoComplete}
                    strengthPassword={false}
                  />
                );
              })}
            </InputFields>
          </CustomForm>
          <FormAction
            handleSubmit={handleSubmit}
            text={translate(LANGUAGE_KEYS.LOGIN)}
            action='submit'
            type='Button'
            disabled={null}
          />
          <FormAction
            handleSubmit={() => {
              navigate('/reset-password');
            }}
            text={translate(LANGUAGE_KEYS.RESET_PASSWORD)}
            action='reset'
            type='Text'
            disabled={null}
          />
          <CreateAccount>
            <Text type={TextType.CAPTION_REGULAR}>
              {translate(LANGUAGE_KEYS.HAVE_NO_ACCOUNT)}
              <FormAction
                handleSubmit={() => {
                  navigate('/signup');
                }}
                text='Create one'
                action={null}
                type='Text'
                disabled={null}
              />
            </Text>
          </CreateAccount>
        </ContentContainer>
      </LoginContainer>
    </>
  );
};

const LoginContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    //Desktop
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 304px;
    min-width: 304px;
    margin-top: 68px;
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 68px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 20px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomForm = styled.form`
  margin-bottom: 16px;
`;

const TitleText = styled(Text)`
  margin-bottom: 48px;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const CreateAccount = styled.div`
  text-align: center;
`;

const ErrorText = styled(Text)`
  color: var(--dts_red);
  margin-bottom: 8px;
  text-align: left;
  line-height: 20px;
`;

export default Login;
