import * as actionTypes from './extensionActionTypes';
import {
  updateWorkTypeExtensionInfo,
  updateTeethWorkTypeExtensionInfo,
  getExtensions,
} from './extensionService';
import UpdateWorkTypeExtensionInfo from '../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import { checkErrorStatus } from '../../ErrorHandling';

const getExtensionsAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_EXTENSIONS });
  return getExtensions().then((extensions) => {
    if (checkErrorStatus(extensions.code, dispatch)) {
      dispatch({ type: actionTypes.GET_EXTENSIONS_SUCCESS, extensions: extensions.data });
      return extensions.data;
    } else {
      dispatch({ type: actionTypes.GET_EXTENSIONS_FAILD, error: extensions.code });
      return extensions.data;
    }
  });
};

const updateExtensionAction = (extension: UpdateWorkTypeExtensionInfo) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_EXTENSIONS });
  return updateWorkTypeExtensionInfo(extension).then((ext) => {
    if (checkErrorStatus(ext.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_EXTENSIONS_SUCCESS });
      return true;
    } else {
      dispatch({ type: actionTypes.UPDATE_EXTENSIONS_FAILD, error: ext.code });
      return false;
    }
  });
};

const updateExtensionTeethAction = (extension: UpdateWorkTypeExtensionInfo) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_EXTENSIONS });
  return updateTeethWorkTypeExtensionInfo(extension).then((ext) => {
    if (checkErrorStatus(ext.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_EXTENSIONS_SUCCESS });
      return true;
    } else {
      dispatch({ type: actionTypes.UPDATE_EXTENSIONS_FAILD, error: ext.code });
      return false;
    }
  });
};

export { getExtensionsAction, updateExtensionAction, updateExtensionTeethAction };
